import React, { useEffect, useState } from 'react';


import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css'; // import odometer styles
import { Link } from 'react-router-dom';
const About = () => {
  const [projectsDone, setProjectsDone] = useState(0);
  const [loansIncrease, setLoansIncrease] = useState(0);
  const [happyClients, setHappyClients] = useState(0);
  const [awardsWon, setAwardsWon] = useState(0);

  useEffect(() => {
    setProjectsDone(100);
    setLoansIncrease(55);
    setHappyClients(95);
    setAwardsWon(15);
  }, []);



  return (
   <>
         <div className="page-title-area item-bg-2">
  <div className="d-table">
    <div className="d-table-cell">
      <div className="container">
        <div className="page-title-content">
          <h2>About Us</h2>
          <ul>
            <li>
               <Link to="/">Home</Link>
            </li>
            <li>About Us</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>


<section className="about-area ptb-100">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-6">
        <div className="about-aboutimg">
       
          <img src="assets/img/myimg/about6.png" alt="aboutimg" />
          {/* <div className="experience">
            <h4>25</h4>
            <p>We have more than years of experience</p>
          </div> */}
        </div>
      </div>
      <div className="col-lg-6">
        <div className="about-content">
          <span><b>About company</b></span>
          <h3>
       <span>Bawasons:</span>    Your Financial Partner
          </h3>
          <p>
          At Bawasons, we are committed to providing accessible and reliable financial solutions to meet your needs. With a focus on transparency and customer satisfaction, we offer a range of loan services designed to empower individuals and families in their financial journey. Our experienced team is dedicated to guiding you through every step of the process, ensuring you get the support and information you need to make informed decisions. At Bawasons, your financial well-being is our priority, and we strive to build lasting relationships with our customers based on trust and honesty.
          </p>
         
       
        </div>
      </div>
    </div>
    <div className="row align-items-center">
     
      <div className="col-lg-6">
        <div className="about-content">
       
          <h3>
          What We Stand For
          </h3>
          <p>
          Our mission is to empower individuals and families by providing accessible and reliable financial solutions. We maintain the highest ethical standards, ensuring trust and integrity in every interaction. Our goal is to empower our clients with personalized financial guidance tailored to their unique needs, making our services accessible to all. We are committed to delivering exceptional service and continually improving our processes to better serve you. In addition, we believe in giving back to the communities we serve, promoting growth and financial well-being for all. These principles define who we are and guide our commitment to helping you achieve your financial goals.
          </p>
         
       
        </div>
      </div>
      <div className="col-lg-6">
        <div className="about-aboutimg">
       
          <img src="assets/img/myimg/about7.png" alt="aboutimg" />
          {/* <div className="experience">
            <h4>25</h4>
            <p>We have more than years of experience</p>
          </div> */}
        </div>
      </div>
    </div>
  </div>
</section>

<section className="fun-facts-area pt-100 pb-70">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-3 col-md-6">
            <div className="single-fun-fact">
              <h3>
                <Odometer>{projectsDone}</Odometer>
              </h3>
              <p>Clients Served</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="single-fun-fact">
              <h3>
                <Odometer>{loansIncrease}</Odometer>
              </h3>
              <p>Loans Processed</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="single-fun-fact">
              <h3>
                <Odometer>{happyClients}</Odometer>
                <span className="sign-icon"> %</span>
              </h3>
              <p>Client Satisfaction</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="single-fun-fact">
              <h3>
                <Odometer>{awardsWon}</Odometer>
              </h3>
              <p>Years of Experience</p>
            </div>
          </div>
        </div>
      </div>
    </section>


   </>
  )
}

export default About
