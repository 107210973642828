import React from 'react'
import { Link } from 'react-router-dom'

const ApplyNow = () => {
  return (
 <>
     <div className="page-title-area item-bg-3">
  <div className="d-table">
    <div className="d-table-cell">
      <div className="container">
        <div className="page-title-content">
          <h2>Apply Now</h2>
          <ul>
            <li>
               <Link to="/">Home</Link>
            </li>
            <li>Apply Now</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<section className="apply-area ptb-100">
  <div className="container">
    <div className="apply-title">
      <h3>Loan Application</h3>
    </div>
    <div className='applynowsection'>

    <div className="row">
      <div className="col-lg-6">
        <form className="apply-form">
          <div className="form-group">
            <label>Desired Loan Amount*</label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label>Firstname</label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label>Date of Birth</label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label>Address*</label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label>Email Address*</label>
            <input type="text" className="form-control" />
          </div>
         
       
          <div className="form-group">
            <label>Purpose of Loan*</label>
            <input type="text" className="form-control" />
          </div>
        </form>
      </div>
      <div className="col-lg-6">
        <form className="apply-form">
          <div className="form-group">
            <label>Annual Income*</label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label>Lastname*</label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label>Gender</label>
            <div className="select-box">
              <select className="form-control" style={{ display: "none" }}>
                <option value={5}>Male</option>
                <option value={1}>Female</option>
              </select>
              <div className="nice-select form-control" tabIndex={0}>
                <span className="current">Male</span>
                <ul className="list">
                  <li data-value={5} className="option selected focus">
                    Male
                  </li>
                  <li data-value={1} className="option">
                    Female
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          <div className="form-group">
            <label>Country*</label>
            <div className="select-box">
              <select className="form-control" style={{ display: "none" }}>
                <option value={5}>India</option>
                <option value={1}>China</option>
                <option value={2}>United Kingdom</option>
                <option value={0}>Germany</option>
                <option value={3}>France</option>
                <option value={4}>Japan</option>
              </select>
              <div className="nice-select form-control" tabIndex={0}>
                <span className="current">India</span>
                <ul className="list">
                  <li data-value={5} className="option selected focus">
                    India
                  </li>
                  <li data-value={1} className="option">
                    China
                  </li>
                  <li data-value={2} className="option">
                    United Kingdom
                  </li>
                  <li data-value={0} className="option">
                    Germany
                  </li>
                  <li data-value={3} className="option">
                    France
                  </li>
                  <li data-value={4} className="option">
                    Japan
                  </li>
                </ul>
              </div>
            </div>
          </div>
     
          <div className="form-group">
            <label>Phone Number*</label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label>Loan Type</label>
            <div className="select-box">
              <select className="form-control" style={{ display: "none" }}>
                <option value={5}>Agricultural Loan</option>
                <option value={1}>Business Loan</option>
                <option value={2}>House Loan</option>
                <option value={0}>Personal Loan</option>
                <option value={3}>Education Loan</option>
                <option value={4}>Payday Loan</option>
              </select>
              <div className="nice-select form-control" tabIndex={0}>
                <span className="current">Agricultural Loan</span>
                <ul className="list">
                  <li data-value={5} className="option selected focus">
                    Agricultural Loan
                  </li>
                  <li data-value={1} className="option">
                    Business Loan
                  </li>
                  <li data-value={2} className="option">
                    House Loan
                  </li>
                  <li data-value={0} className="option">
                    Personal Loan
                  </li>
                  <li data-value={3} className="option">
                    Education Loan
                  </li>
                  <li data-value={4} className="option">
                    Payday Loan
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div className="apply-btn">
      <button type="submit" className="default-btn">
        Apply now
        <span style={{ top: "13.8125px", left: "217.391px" }} />
      </button>
    </div>
    </div>
   
  </div>
</section>

 </>
  )
}

export default ApplyNow
