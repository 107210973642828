import React from 'react'
import { Link } from 'react-router-dom'

const TermsConditions = () => {
  return (
    <>
    <div className="page-title-area item-bg-2"
    style={{backgroundImage:'url(assets/img/myimg/terms.jpg)'}}
    >
<div className="d-table">
<div className="d-table-cell">
<div className="container">
  <div className="page-title-content">
    <h2>Terms & Conditions</h2>
    <ul>
      <li>
        
        <Link to="/">Home</Link>
      </li>
      <li>Terms & Conditions</li>
    </ul>
  </div>
</div>
</div>
</div>
</div>
<section className='terms-of-condition-area ptb-100'>
<div className='container'>
      
      <p>
        These Terms and Conditions ("Terms") govern your use of the Bawasons Holding Pvt Ltd website and services. By accessing or using our services, you agree to comply with and be bound by these Terms. If you disagree with any part of the terms, please do not use our website or services.
      </p>

      <h4>Acceptance of Terms</h4>
      <p>
        By accessing our website, you accept and agree to be bound by these Terms, our Privacy Policy, and any other relevant legal notices and agreements. We reserve the right to amend these Terms at any time, and it is your responsibility to review them periodically.
      </p>

      <h4> Use of Our Services</h4>
      <p>
        Bawasons Holding Pvt Ltd provides loan services to clients. You agree to use our services only for lawful purposes and in a way that does not infringe the rights of others or restrict or inhibit their use and enjoyment of our services.
      </p>

      <h4>Eligibility</h4>
      <p>
        To be eligible to use our services, you must be at least 18 years old and legally capable of entering into binding contracts. By using our services, you represent and warrant that you meet these eligibility requirements.
      </p>

      <h4>Loan Terms</h4>
      <p>
        Loan terms, including interest rates, repayment schedules, and any associated fees, will be detailed in individual loan agreements. It is your responsibility to carefully read and understand these agreements before signing.
      </p>

      <h4>Account Security</h4>
      <p>
        You are responsible for maintaining the confidentiality of your account information, including your login credentials. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
      </p>

      <h4>Limitation of Liability</h4>
      <p>
        Bawasons Holding Pvt Ltd shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of our services. Our total liability to you for any damages arising out of or related to these Terms will not exceed the amount paid by you, if any, for accessing our services.
      </p>

      <h4>Termination</h4>
      <p>
        We reserve the right to terminate or suspend your access to our services at any time, without notice, for conduct that we believe violates these Terms or is harmful to other users of our services, us, or third parties, or for any other reason.
      </p>

      <h4>Changes to the Terms</h4>
      <p>
        We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on this page. Your continued use of the services after any changes indicates your acceptance of the new Terms.
      </p>

      <h4>Governing Law</h4>
      <p>
        These Terms shall be governed by and construed in accordance with the laws of [your jurisdiction], without regard to its conflict of law principles. Any disputes arising under these Terms shall be subject to the exclusive jurisdiction of the courts located in [your location].
      </p>

      <h4> Contact Us</h4>
      <p>
        If you have any questions or comments about this policy, you may email us at <a href='mailto:info@bawasons.com'>info@bawasons.com</a> .
      </p>
    </div>
</section>

</>
  )
}

export default TermsConditions
