import React from 'react'
import { Link } from 'react-router-dom'

const PrivacyPolicy = () => {
  return (
 <>
          <div className="page-title-area item-bg-2"
          style={{backgroundImage:'url(assets/img/myimg/privacy.jpg)'}}
          >
  <div className="d-table">
    <div className="d-table-cell">
      <div className="container">
        <div className="page-title-content">
          <h2>Privacy Policy</h2>
          <ul>
            <li>
              
              <Link to="/">Home</Link>
            </li>
            <li>Privacy Policy</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
 <section className='terms-of-condition-area ptb-100'>
 <div className='container'>
     
      <p>
        Welcome to Bawasons Holding Pvt Ltd. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy or practices with regards to your personal information, please contact us at [your contact email].
      </p>
      <h4>Information We Collect</h4>
      <p>
        We collect personal information that you provide to us when expressing an interest in obtaining information about us, our products and services, when participating in activities on the website, or otherwise contacting us.
      </p>
      <p>
        The personal information we collect depends on the context of your interactions with us, the choices you make, and the products and features you use. The personal information we collect may include:
      </p>
      <ul>
        <li>Personal Identifiers: Name, address, email address, phone number, and other similar contact data.</li>
        <li>Financial Information: Bank account details, credit information, and transaction history.</li>
        <li>Usage Data: Information automatically collected when you visit our website, including IP address, browser type, and other technical data.</li>
      </ul>

      <h4>How We Use Your Information</h4>
      <p>
        We use personal information collected via our website for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.
      </p>
      <ul>
        <li>To facilitate account creation and login process.</li>
        <li>To process your financial transactions and manage your accounts.</li>
        <li>To send administrative information to you such as updates to our terms, conditions, and policies.</li>
        <li>To protect our services, including fraud monitoring and prevention.</li>
        <li>To respond to legal requests and prevent harm as necessary.</li>
      </ul>

      <h4>Sharing Your Information</h4>
      <p>
        We do not share, sell, rent, or trade your information with third parties without your consent, except as described in this privacy policy. We may disclose your personal information to trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.
      </p>

      <h4> Data Security</h4>
      <p>
        We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure.
      </p>

      <h4> Your Privacy Rights</h4>
      <p>
        You have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.
      </p>

      <h4> Contact Us</h4>
      <p>
        If you have any questions or comments about this policy, you may email us at <a href='mailto:info@bawasons.com'>info@bawasons.com</a> .
      </p>

     
    </div>
 </section>
 
 </>
  )
}

export default PrivacyPolicy
