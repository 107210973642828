import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [loanType, setLoanType] = useState('Select Loan Type'); // Default value
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can handle form submission
    const formData = { name, email, phone, loanType, message };
    console.log(formData);
  };
  return (
    <>
      <div className="page-title-area item-bg-1">
  <div className="d-table">
    <div className="d-table-cell">
      <div className="container">
        <div className="page-title-content">
          <h2>Contact Bawasons</h2>
          <ul>
            <li>
               <Link to="/">Home</Link>
            </li>
            <li>Contact</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<section className="contact-area ptb-100">
  <div className="container">
    <div className="section-title">
      <span><b>Get In Touch</b></span>
      <h2>We're Here for Your Finance Needs!
      </h2>
      <p> For inquiries about our loan process, <br/>fill out the form below or contact us via phone or email. Our team is ready to assist you!</p>
    </div>
    <div className="row mt-5">
    <div className="col-lg-4">
        <div className="contact-side-box">
       
        
          <div className="info-box">
            <div className="icon">
              <i className="flaticon-pin" />
            </div>
            <h4>Address</h4>
            <span>
            A-78 Sector-2 Noida
            </span>
          </div>
          <div className="info-box">
            <div className="icon">
              <i className="flaticon-phone-call" />
            </div>
            <h4>Phone</h4>
            <span>
              <a href="tel:1514312-6688">+91 9990799940</a>
            </span>
         
          </div>
          <div className="info-box">
            <div className="icon">
              <i className="flaticon-email" />
            </div>
            <h4>Email</h4>
            <span>
              <a href="mailto:info@bawasons.com">info@bawasons.com</a>
            </span>
          
          </div>
        </div>
      </div>
      <div className="col-lg-8">
        <div className="contact-form">
      
        <form id="contactForm" noValidate onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="form-group has-error">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              data-error="Please enter your name"
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="form-group has-error">
            <label>Email Address</label>
            <input
              type="email"
              className="form-control"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              data-error="Please enter your email"
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="form-group has-error">
            <label>Phone Number</label>
            <input
              type="tel"
              className="form-control"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              data-error="Please enter your phone"
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="form-group">
            <label>Loan Type</label>
            <select
              className="form-control"
              value={loanType}
              onChange={(e) => setLoanType(e.target.value)}
            >
              <option value="Select Loan Type" disabled>Select Loan Type</option>
              <option value="Personal Loan">Personal Loan</option>
              <option value="Business Loan">Business Loan</option>
              <option value="Home Loan">Home Loan</option>
              <option value="Education Loan">Education Loan</option>
            </select>
          </div>
        </div>
        <div className="col-lg-12 col-md-12">
          <div className="form-group">
            <label>Message</label>
            <textarea
              className="form-control"
              cols={30}
              rows={5}
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              data-error="Write your message"
            />
          </div>
        </div>
        <div className="col-lg-12 col-md-12">
          <button
            type="submit"
            className="default-btn"
            style={{ pointerEvents: "all", cursor: "pointer" }}
          >
            Send message
            <span />
          </button>
        </div>
      </div>
    </form>
        </div>
      </div>
   
    </div>
  </div>
</section>


<div className="map">
  <div className="container-fluid">
  <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7006.989308139545!2d77.30997303959103!3d28.58493382388319!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce458ccb0afa5%3A0x6f6a3dc21c831e29!2sSector%202%2C%20Noida%2C%20Uttar%20Pradesh%20201301!5e0!3m2!1sen!2sin!4v1726915496334!5m2!1sen!2sin"
  width={600}
  height={450}
  style={{ border: 0 }}
  allowFullScreen=""
  loading="lazy"
  title="Unique description of iframe content"
  referrerPolicy="no-referrer-when-downgrade"
/>

  </div>
</div>

    </>
  )
}

export default Contact
