

import Home from './components/Home';
import Layout from './components/Layout';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import About from './pages/About';
import Contact from './pages/Contact';
import ApplyNow from './pages/ApplyNow';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
function App() {
  return (

<Router>
<Layout>
<Routes>
<Route path="/" element={<Home />} />
<Route path="about" element={<About/>}/>
<Route path="contact" element={<Contact/>}/>
<Route path="privacy" element={<PrivacyPolicy/>}/>
<Route path="terms" element={<TermsConditions/>}/>
<Route path="applynow" element={<ApplyNow/>}/>
</Routes>

</Layout>
</Router>
 

  );
}

export default App;
