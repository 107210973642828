import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  // State to control the visibility of the responsive menu
  const [isCanvasOpen, setIsCanvasOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false); // State to control the submenu
  const [isSticky, setIsSticky] = useState(false); // State to control sticky navbar
  const [logoSrc, setLogoSrc] = useState('assets/img/myimg/bawasonslogo.png'); // State to control logo source

  // Function to toggle the canvas menu
  const toggleCanvas = () => {
    setIsCanvasOpen(!isCanvasOpen);
  };

  // Function to toggle the submenu
  const toggleSubMenu = (e) => {
    e.preventDefault(); // Prevent default anchor click behavior
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  // Function to handle scroll and set sticky navbar state
  const handleScroll = () => {
    if (window.scrollY > 50) { // Adjust the scroll position to trigger sticky state
      setIsSticky(true);
      setLogoSrc('assets/img/myimg/bawasonslogo.png'); // Change to sticky logo
    } else {
      setIsSticky(false);
      setLogoSrc('assets/img/myimg/bawasonslight.png'); // Default logo
    }
  };

  // Effect to add/remove scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`navbar-area ${isSticky ? 'is-sticky' : ''}`}>
      <div className="main-responsive-nav">
        <div className="container">
          <div className="main-responsive-menu">
            <div className="logo">
              <Link to="/">
                <img src={logoSrc} alt="blogo" /> {/* Dynamic Logo */}
              </Link>
            </div>
            {/* Hamburger Icon for Mobile */}
            <div className="menu-bar" onClick={toggleCanvas}>
              <i className="bx bx-menu"></i> {/* Icon for the bar */}
            </div>
          </div>
        </div>
      </div>

      {/* Off-Canvas Menu */}
      <div className={`offcanvas-menu ${isCanvasOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={toggleCanvas}>
          &times;
        </button>
        <ul className="canvas-nav-links">
          <li className="nav-item">
            <Link to="/" className="nav-link" onClick={toggleCanvas}>
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/about" className="nav-link" onClick={toggleCanvas}>
              About Us
            </Link>
          </li>
          <li className="nav-item">
            <a href="#loanservice" className="nav-link" onClick={toggleSubMenu}>
              Services{' '}
              {/* <i className={`bx ${isSubMenuOpen ? 'bx-chevron-up' : 'bx-chevron-down'}`} /> */}
            </a>
            {isSubMenuOpen && (
              <ul className="dropdown-menu">
                <li className="nav-item">
                  <Link to="/" className="nav-link" onClick={toggleCanvas}>
                    Home Loan
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/" className="nav-link" onClick={toggleCanvas}>
                    Personal Loan
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/" className="nav-link" onClick={toggleCanvas}>
                    Business Loan
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/" className="nav-link" onClick={toggleCanvas}>
                    Education Loan
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="nav-item">
            <Link to="/" className="nav-link" onClick={toggleCanvas}>
              Funding
            </Link>
          </li>
          <li className="nav-item">
            <a href="#loanservice" className="nav-link" onClick={toggleCanvas}>
              Loans
            </a>
          </li>
          <li className="nav-item">
            <Link to="/contact" className="nav-link" onClick={toggleCanvas}>
              Contact
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/applynow" className="default-btn" onClick={toggleCanvas}>
              Contact Us
              <span />
            </Link>
          </li>
        </ul>
      </div>

      <div className="main-navbar">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-md navbar-light">
            <Link className="navbar-brand" to="/">
              <img src={logoSrc} alt="blogo" /> {/* Dynamic Logo */}
            </Link>
            <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about" className="nav-link">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <a href="#loanservice" className="nav-link">
                    Services <i className="bx bx-chevron-down" />
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <Link to="/" className="nav-link">
                        Home Loan
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" className="nav-link">
                        Personal Loan
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" className="nav-link">
                        Business Loan
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" className="nav-link">
                        Education Loan
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    Funding
                  </Link>
                </li>
                <li className="nav-item">
                  <a href="#loanservice" className="nav-link">
                    Loans
                  </a>
                </li>
                {/* <li className="nav-item">
                  <Link to="/contact" className="nav-link">
                    Contact
                  </Link>
                </li> */}
              </ul>
              <div className="others-options d-flex align-items-center">
                <div className="option-item">
                  <Link to="/contact" className="default-btn">
                    Contact Us
                    <span />
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
