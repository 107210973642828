import React from 'react'
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
import '../App.css'
// Import Swiper styles
// import 'swiper/css';
// import { Pagination, Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';
const Home = () => {
  return (
    <>
        <div
    className="main-banner"
    style={{ backgroundImage: "url(./assets/img/myimg/banner-slider1.jpg)" }}
  >
    <div className="d-table">
      <div className="d-table-cell">
        <div className="container">
          <div className="main-banner-content">
            <h1 data-aos="fade-up" data-aos-delay={100} data-aos-duration={800}>
              Finance Your Dreams with Bawasons !
            </h1>
            <p data-aos="fade-up" data-aos-delay={200} data-aos-duration={800}>
              Flexible Loan Solutions Tailored for You. Apply Now and Secure Your
              Future with Us
            </p>
            <Link
              className="btn default-btn"
              data-aos="fade-up"
              data-aos-delay={300}
              data-aos-duration={800}
              to="/"
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div className="approvals-area ptb-100 bg-color">
  <div className="container">
    <div className="approvals-item">
      <div className="row">
        <div className="col-lg-4 col-md-6">
          <div className="approvals-content">
            <div className="icon">
              <i className="flaticon-loan" />
            </div>
            <span>Quick</span>
            <p>Loan approvals</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="approvals-content">
            <div className="icon">
              <i className="flaticon-satisfaction" />
            </div>
            <span>Customised </span>
            <p>Loan Solutions</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
          <div className="approvals-content">
            <div className="icon">
              <i className="flaticon-document" />
            </div>
            <span>No prepayment or</span>
            <p>hidden fees</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


  {/* Start About Area Area */}
  <section className="about-area bg-color pb-100">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="about-content">
            <span>About company</span>
            <h3>
              Make Your Financial Dreams a Reality with <span>Bawasons</span>
            </h3>
            <p>
              At Bawasons, we are committed to helping individuals and
              businesses achieve their financial goals by offering tailored loan
              solutions. Our mission is to simplify the borrowing process and
              provide accessible financial support to those who need it the
              most. Whether you’re looking for personal loans, business loans,
              or other financial services, we are here to provide you with the
              right solution.
            </p>
            <strong>Our Unique Advantages</strong>
            <ul className="value-features">
              <li>
                <i className="flaticon-idea" />
                Tailored Loan Solutions
              </li>
              <li>
                <i className="flaticon-loan" />
                Fast &amp; Simple Process
              </li>
              <li>
                <i className="flaticon-user-experience" />
                Trusted Partner
              </li>
              <li>
                <i className="flaticon-talent" />
                Expert Guidance
              </li>
            </ul>
            <div className="about-btn mt-5">
              <Link to="/" className="default-btn">
                About more
                <span style={{ top: "114.25px", left: "37.5px" }} />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-wrap">
            <img src="assets/img/myimg/about2.png" alt="img" />
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* End About Area Area */}


  {/* Start Choose Area */}
  <section className="choose-area pt-70 pb-70">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="choose-title">
            <span>Transparent process</span>
            <h2>Why people choose us</h2>
            <p>
              At Bawasons, we strive to make your loan process smooth and
              stress-free. Here’s why so many customers trust us to meet their
              financial needs.
            </p>
          </div>
          <div className="choose-image">
            <img src="assets/img/myimg/about.png" alt="chooseusimage" />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div
                className="process-item bg-top1 "
                data-aos="fade-up"
                data-aos-delay={100}
                data-aos-duration={800}
              >
                <div className="icon">
                  <i className="flaticon-guarantee" />
                </div>
                <h3>Guarantee</h3>
                <p>
                  We offer clear and transparent loan services, with no hidden
                  fees, so you can trust our terms.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div
                className="process-item bg-top2"
                data-aos="fade-up"
                data-aos-delay={200}
                data-aos-duration={800}
              >
                <div className="icon">
                  <i className="flaticon-speed" />
                </div>
                <h3>Speed</h3>
                <p>
                  Our quick and easy application process ensures you get your
                  loan fast, without unnecessary delays.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div
                className="process-item bg-top3"
                data-aos="fade-up"
                data-aos-delay={300}
                data-aos-duration={800}
              >
                <div className="icon">
                  <i className="flaticon-reliability" />
                </div>
                <h3>Reliability</h3>
                <p>
                  Count on us for dependable financial solutions, designed to
                  meet your needs with flexibility.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div
                className="process-item bg-top4"
                data-aos="fade-up"
                data-aos-delay={400}
                data-aos-duration={800}
              >
                <div className="icon">
                  <i className="flaticon-user-experience" />
                </div>
                <h3>Experience</h3>
                <p>
                  With years of expertise, our team provides personalized loan
                  options to fit your financial goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* End Choose Area */}

   {/* Start Solution Area */}
   <section className="solution-area">
    <div className="container">
      <div className="solution-item">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="content">
              <h3>Let us help you move forward! </h3>
              <p style={{ color: "#fff" }}>
                Reach out today for personalized loan options designed to meet
                your needs.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="solution-info">
              <Link to="/contact" className="default-btn">
                Contact Us
                <span style={{ top: "114.25px", left: "37.5px" }} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* End Solution Area */}

   {/* Start Company Area */}
   <section className="company-area bg-color ptb-100">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="company-content">
            <h3>Our company values</h3>
            <div
              className="company-text"
              data-aos="fade-up"
              data-aos-delay={100}
              data-aos-duration={800}
            >
              <div className="icon">
                <i className="flaticon-idea" />
              </div>
              <h4>Innovative</h4>
              <p>
                We continuously explore new approaches and technologies to
                enhance our loan offerings and streamline your financial
                experience.
              </p>
            </div>
            <div
              className="company-text"
              data-aos="fade-up"
              data-aos-delay={200}
              data-aos-duration={800}
            >
              <div className="icon">
                <i className="flaticon-talent" />
              </div>
              <h4>Talent</h4>
              <p>
                Our team is composed of skilled professionals who bring
                expertise and creativity to deliver exceptional financial
                solutions tailored to your needs.
              </p>
            </div>
            <div
              className="company-text"
              data-aos="fade-up"
              data-aos-delay={300}
              data-aos-duration={800}
            >
              <div className="icon">
                <i className="flaticon-key" />
              </div>
              <h4>Enabling</h4>
              <p>
                We empower our clients by providing the tools and support needed
                to make informed financial decisions and achieve their goals.
              </p>
            </div>
            <div
              className="company-text"
              data-aos="fade-up"
              data-aos-delay={400}
              data-aos-duration={800}
            >
              <div className="icon">
                <i className="flaticon-responsibility" />
              </div>
              <h4>Commercially responsible</h4>
              <p>
                We practice responsible lending, ensuring our services are
                aligned with ethical standards and contribute to your financial
                well-being.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="company-wrap">
            <img src="assets/img/myimg/whychoose.png" alt="company" />
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* End Company Area */}

   {/* Start Best Services Area */}
   <section className="best-services-area ptb-100" id="loanservice">
  <div className="container">
    <div className="section-title">
      <span>Our Services</span>
      <h2>Our Financial Solutions</h2>
    </div>
    <div className="best-services-slider">
      <div id="thumbs" className="owl-carousel owl-theme">
        <div className="tabs-item">
          <Link to="/">
            <i className="flaticon-agriculture" />
            <span>Agricultural loan</span>
          </Link>
        </div>
        <div className="tabs-item">
          <Link to="/">
            <i className="flaticon-personal" />
            <span>Personal loan</span>
          </Link>
        </div>
        <div className="tabs-item">
          <Link to="/">
            <i className="flaticon-loan-1" />
            <span>Business loan</span>
          </Link>
        </div>
        <div className="tabs-item">
          <Link to="/">
            <i className="flaticon-scholarship" />
            <span>Education loan</span>
          </Link>
        </div>
        <div className="tabs-item">
          <Link to="/">
            <i className="flaticon-loan-2" />
            <span>Home loan</span>
          </Link>
        </div>
        <div className="tabs-item">
          <Link to="/">
            <i className="flaticon-loan-3" />
            <span>Payday loan</span>
          </Link>
        </div>
      </div>
      <div id="best-services" className="owl-carousel owl-theme">
        <div className="services-tabs-item">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="services-tab-image">
                <img
                  src="assets/img/myimg/loans/agreecult-removebg-preview.png"
                  alt="Agricultural"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="services-tab-content">
                <h3>Agricultural Loan</h3>
                <p>
                  Our agricultural loans are designed to support farmers and agribusinesses with the funds needed for equipment, land, and operational costs. We offer flexible terms and competitive rates to help you grow your agricultural business sustainably.
                </p>
              </div>
              <ul className="list">
                <h3>Types of Agricultural Loans</h3>
                <li>Crop Loans</li>
                <li>Equipment Loans</li>
                <li>Livestock Loans</li>
                <li>Land Purchase Loans</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="services-tabs-item">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="services-tab-image">
                <img
                  src="assets/img/myimg/loans/allloan-removebg-preview.png"
                  alt="Personal Loan"
                  style={{ width: 405 }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="services-tab-content">
                <h3>Personal Loan</h3>
                <p>
                  Our personal loans are perfect for individuals who need funds for various personal needs such as debt consolidation, medical expenses, or major life events. Enjoy hassle-free application and quick approvals.
                </p>
              </div>
              <ul className="list">
                <h3>Types of Personal Loans</h3>
                <li>Unsecured Personal Loans</li>
                <li>Secured Personal Loans</li>
                <li>Debt Consolidation Loans</li>
                <li>Emergency Loans</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="services-tabs-item">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="services-tab-image">
                <img
                  src="assets/img/myimg/loans/business-removebg-preview.png"
                  alt="Business Loan"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="services-tab-content">
                <h3>Business Loan</h3>
                <p>
                  Our business loans are tailored for entrepreneurs and companies seeking to expand operations, invest in new projects, or manage cash flow. Benefit from our customized solutions designed to meet the unique needs of your business.
                </p>
              </div>
              <ul className="list">
                <h3>Types of Business Loans</h3>
                <li>Secured Business Loans</li>
                <li>Unsecured Business Loans</li>
                <li>Revolving Credit Facilities</li>
                <li>Business Cash Advances</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="services-tabs-item">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="services-tab-image">
                <img
                  src="assets/img/myimg/loans/education-removebg-preview.png"
                  alt="Education Loan"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="services-tab-content">
                <h3>Education Loan</h3>
                <p>
                  Our education loans provide the financial support you need for tuition fees, books, and other educational expenses. Designed for students and parents alike, our loans offer flexible terms and competitive interest rates.
                </p>
              </div>
              <ul className="list">
                <h3>Types of Education Loans</h3>
                <li>Undergraduate Loans</li>
                <li>Postgraduate Loans</li>
                <li>Professional Development Loans</li>
                <li>Parent Loans</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="services-tabs-item">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="services-tab-image">
                <img src="assets/img/myimg/loans/home.png" alt="Home Loan" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="services-tab-content">
                <h3>Home Loan</h3>
                <p>
                  Achieve your dream of homeownership with our home loan solutions. We offer various mortgage options with competitive rates and flexible terms to help you purchase or refinance your home.
                </p>
              </div>
              <ul className="list">
                <h3>Types of Home Loans</h3>
                <li>Fixed-Rate Mortgages</li>
                <li>Adjustable-Rate Mortgages</li>
                <li>Refinancing Loans</li>
                <li>Home Equity Loans</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="services-tabs-item">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="services-tab-image">
                <img src="assets/img/myimg/loans/payday.png" alt="Payday Loan" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="services-tab-content">
                <h3>Payday Loan</h3>
                <p>
                  Our payday loans provide quick financial assistance for unexpected expenses or urgent cash needs. With fast approval and minimal requirements, our payday loans help you manage short-term financial gaps efficiently.
                </p>
              </div>
              <ul className="list">
                <h3>Types of Payday Loans</h3>
                <li>Single-Payment Payday Loans</li>
                <li>Installment Payday Loans</li>
                <li>Flexible Payday Loans</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  {/* End Best Services Area */}

  {/* Start Clients Area */}
  {/* <section className="clients-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span>Clients words</span>
          <h2>What our clients say</h2>
        </div>
        <div className="row">
        <Swiper
            spaceBetween={30} 
            pagination={{
              clickable: true, 
            }}
            autoplay={{
              delay: 3000, 
              disableOnInteraction: false, 
            }}
            breakpoints={{
              0: { 
                slidesPerView: 1, 
              },
              768: {
                slidesPerView: 2, 
              },
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
          >
           
            <SwiperSlide>
              <div className="clients-item">
                <div className="info">
                  <img src="assets/img/myimg/man2.png" alt="img" />
                  <h4>Markus Twain</h4>
                  <span>Client</span>
                </div>
                <p>
                  "I was impressed with how fast and efficient Bawasons was in
                  handling my business loan. The flexible terms and competitive
                  rates were exactly what my company needed to grow. Thank you,
                  Bawasons!"
                </p>
                <div className="icon">
                  <i className="flaticon-right-quote" />
                </div>
              </div>
            </SwiperSlide>

         
            <SwiperSlide>
              <div className="clients-item">
                <div className="info">
                  <img src="assets/img/myimg/man2.png" alt="img" />
                  <h4>Emily R</h4>
                  <span>Student</span>
                </div>
                <p>
                  "As a student, I was worried about financing my education.
                  Bawasons provided a solution with flexible repayment options that
                  fit my budget. I’m grateful for their assistance in making my
                  academic dreams come true!"
                </p>
                <div className="icon">
                  <i className="flaticon-right-quote" />
                </div>
              </div>
            </SwiperSlide>

             
              <SwiperSlide>
              <div className="clients-item">
                <div className="info">
                  <img src="assets/img/myimg/man2.png" alt="img" />
                  <h4>Emily R</h4>
                  <span>Student</span>
                </div>
                <p>
                  "As a student, I was worried about financing my education.
                  Bawasons provided a solution with flexible repayment options that
                  fit my budget. I’m grateful for their assistance in making my
                  academic dreams come true!"
                </p>
                <div className="icon">
                  <i className="flaticon-right-quote" />
                </div>
              </div>
            </SwiperSlide>
               
                <SwiperSlide>
              <div className="clients-item">
                <div className="info">
                  <img src="assets/img/myimg/man2.png" alt="img" />
                  <h4>Emily R</h4>
                  <span>Student</span>
                </div>
                <p>
                  "As a student, I was worried about financing my education.
                  Bawasons provided a solution with flexible repayment options that
                  fit my budget. I’m grateful for their assistance in making my
                  academic dreams come true!"
                </p>
                <div className="icon">
                  <i className="flaticon-right-quote" />
                </div>
              </div>
            </SwiperSlide>

           
          </Swiper>
        </div>
      </div>
    </section> */}
  {/* End Clients Area */}


    </>





  )
}

export default Home
